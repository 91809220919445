<template>
    <div v-if="bien" class="flex items-center mb-4 sm:mb-0 flex-wrap gap-2" :class="{'text-white': selectionMoment}">
        <div class="flex items-center px-[.625rem] py-2 rounded text-body-14"
             :class="{'group-hover:bg-white': !option && !selectionMoment, 'group-hover:bg-forest3 bg-forest3': selectionMoment, 'bg-grey2': !selectionMoment}">
            <icons-home-outline
                class="w-5 mr-2"
                :class="[selectionMoment ? 'text-white': 'text-grey6']">
            </icons-home-outline>
            {{ bien.nbPiece === 1 ? 'Studio' : 'T' + bien.nbPiece }}
        </div>

        <div class="flex items-center px-[.625rem] py-2 rounded text-body-14"
             :class="{'group-hover:bg-white': !option && !selectionMoment, 'group-hover:bg-forest3 bg-forest3': selectionMoment, 'bg-grey2': !selectionMoment}">
            <icons-map-solid
                class="w-5 mr-2"
                :class="[selectionMoment ? 'text-white': 'text-grey6']">
            </icons-map-solid>
            {{ frenchizer(bien.surface) }} m²
        </div>

        <div class="flex w-fit" v-if="dpe != null">
            <div class="flex justify-start rounded-l font-bold text-white leading-[2rem] items-center px-2 h-9 dpe text-2xl"
                 :style="{background: disabled ? '#DBD6D5' : dpeLetters[dpe].color}"
            >
                {{ dpe }}
            </div>

            <div class="border-t-transparent border-b-transparent border-r-transparent border-t-[1.125rem] border-l-[0.95rem] border-b-[1.125rem]"
                 :style="{'border-left-color': disabled ? '#DBD6D5' : dpeLetters[dpe].color}">
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    bien: {
        type: Object,
        required: true
    },
    selectionMoment: {
        type: Boolean,
        default: false
    },
    dpe: {
        type: [String, null],
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const option = computed(() => {
    if (props.bien) {
        return props.bien.optionne === true;
    }

    return false;
});

const dpeLetters = computed(() => {
    return {
        'A': {color: '#4ABA9C'},
        'B': {color: '#94CE9D'},
        'C': {color: '#BCE777'},
        'D': {color: '#FFDA3C'},
        'E': {color: '#FFA92E'},
        'F': {color: '#F56E58'},
        'G': {color: '#E64457'},
    };
});
</script>

<style scoped lang="scss">
.dpe {
    text-shadow: 0 0 12px rgba(24, 103, 65, 0.35);
}
</style>